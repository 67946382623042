import Input from "src/_elements/input/input";
import styles from "./fbModal.module.scss";
import { useEffect, useState } from "react";
import Modal from "src/_elements/modal/modal";
import {
  patchFacebookPixelApi,
  postFacebookPixelApi,
} from "src/_utils/api/api";

const FbModal: React.FC<any> = ({
  isShow,
  handleClose,
  item,
  isEdit,
  setIsLoading,
}) => {
  const [displayName, setDisplayName] = useState("");
  const [facebookPixelId, setFacebookPixelId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isEdit) {
      setDisplayName(item.displayName);
      setFacebookPixelId(item.facebookPixelId);
    }
  }, [isEdit, item]);

  const handleSave = () => {
    if (displayName === "" || facebookPixelId === "") {
      setErrorMessage("Please fill in all fields");
    } else if (facebookPixelId.length > 18) {
      setErrorMessage(
        "facebook pixel id length must be less than or equal to 18 characters long"
      );
    } else {
      if (!isEdit) {
        const payload = {
          displayName: displayName,
          facebookPixelId: facebookPixelId,
        };

        postFacebookPixelApi(payload).then(() => {
          setIsLoading(true);
          handleClose();
        });
      } else {
        const payload = {
          displayName: displayName,
          facebookPixelId: facebookPixelId,
        };

        patchFacebookPixelApi(payload, item.id).then(() => {
          setIsLoading(true);
          handleClose();
        });
      }
    }
  };

  const handleChangeFacebookPixelId = (value: string) => {
    if (/^\d*$/.test(value)) {
      setFacebookPixelId(value);
    }
  };

  return (
    <Modal className={styles.wrapper} isShow={isShow} onClose={handleClose}>
      <div className={styles.content}>
        <p className={styles.title}>
          {isEdit ? "Edit Facebook pixel" : "Add new Facebook pixel"}
        </p>
        <div className={styles.inputs}>
          <Input
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Display name"
            label="Display name"
          />
          <Input
            value={facebookPixelId}
            onChange={(e) => handleChangeFacebookPixelId(e.target.value)}
            placeholder="Pixel ID"
            label="Pixel ID"
          />
        </div>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <div className={styles.btnWrap}>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </Modal>
  );
};

export default FbModal;
